import React, { FC, MouseEvent, useEffect, useMemo, useRef, useState, ChangeEvent, useCallback } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Error as ErrorIcon, Search, HighlightOff, Clear } from '@mui/icons-material';
import { CircularProgress, Link, Menu, Icon, InputAdornment, Dialog } from '@mui/material';
import { format, addDays } from 'date-fns';
import { debounce, isEqual } from 'lodash';
import ReactGA from 'react-ga4';
import { toast } from 'react-toastify';
import { DateRange } from 'rsuite/esm/DateRangePicker';
import Button from 'components/Button/Button';
import { ChooseColumnsMenu } from 'components/CommissionHistoryTableV2/ChooseColumnsMenu/ChooseColumnsMenu';
import { CommissionHistoryTableV2 } from 'components/CommissionHistoryTableV2/CommissionHistoryTable/CommissionHistoryTableV2';
import { FilterStatusMenu } from 'components/CommissionHistoryTableV2/FilterStatusMenu/FilterStatusMenu';
import DateRangePicker from 'components/DateRangePicker/DateRangePicker';
import TextField from 'components/TextField/TextField';
import { exportJSONToCSV, findErrorMessage } from 'helpers';
import { hasWildfirePermissionsSelector } from 'helpers/auth0';
import { DATE_FORMAT } from 'helpers/constants';
import useTimeOnPageGA from 'hooks/useTimeOnPageGA';
import { useGetApplicationDetailsQuery } from 'reduxState/store/application/api';
import { useCreateCommissionDownloadMutation, useLazyGetCommissionsQuery } from 'reduxState/store/commission/api';
import { commissionColumnsSelector } from 'reduxState/store/commission/selectors';
import {
  CombinedColumn,
  CommissionColumn,
  CommissionColumnVisible,
  CommissionWithMerchantName,
  userCommissionColumn,
  FilterStatus,
  DisplayCommission,
  CsvCommissionWithMerchantName,
  CsvCommissionColumn,
} from 'reduxState/store/commission/types';
import { useLazyGetMerchantsQuery } from 'reduxState/store/merchant/api';
import { useGetDeviceQuery } from 'reduxState/store/user/api';
import exportIcon from 'static/images/export-icon-black.png';
import infoIcon from 'static/images/info-icon.png';
import menuBookIcon from 'static/images/menu-book-black.png';
import { useAppSelector, useAppDispatch } from '../../reduxState/hooks';
import { setCommissionColumns } from '../../reduxState/store/commission/slice';
import { Merchant } from '../../reduxState/store/merchant/types';

import './CommissionHistory.scss';

interface OwnProps {
  applicationId: string;
  adminAppId: string;
  appName: string;
  advancedSearch?: boolean;
}

const EDIT_COLUMNS_MENU_ID = 'edit-columns-menu';
const FILTER_STATUS_MENU_ID = 'filter-status-menu';

const CommissionHistory: FC<React.PropsWithChildren<OwnProps>> = ({
  applicationId,
  advancedSearch,
  adminAppId,
  appName,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { user } = useAuth0();
  const [inputValue, setInputValue] = useState('');
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [pageNumber, setPageNumber] = useState(2);
  const [isDownloading, setIsDownloading] = useState(false);
  const [loadedCommissions, setLoadedCommissions] = useState<CommissionWithMerchantName[]>([]);
  const [merchants, setMerchants] = useState<{ [MerchantID: number]: Merchant }>({});
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [callbackVisible, setCallbackVisible] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [getMerchants] = useLazyGetMerchantsQuery();
  const [downloadError, setDownloadError] = useState(false);
  const [editColumnsAnchorEl, setEditColumnsAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [filterStatusAnchorEl, setFilterStatusAnchorEl] = useState<HTMLElement | null>(null);
  const [statusFilters, setStatusFilters] = useState<FilterStatus>({
    PENDING: true,
    READY: true,
    PAID: true,
    CONFIRMED: true,
  });
  const [getCommissions, { error: commissionError }] = useLazyGetCommissionsQuery();
  const [createCommissionDownloadJob, { error: commissionDownloadError }] = useCreateCommissionDownloadMutation();

  const hasWfPermissions = hasWildfirePermissionsSelector(user);
  useTimeOnPageGA(user, applicationId, 'Commission History');

  const columns = useAppSelector(commissionColumnsSelector);

  const { data: deviceData, error: deviceTokenError, isFetching: isDeviceTokenFetching } = useGetDeviceQuery(
    applicationId,
  );
  const deviceToken = deviceData?.DeviceToken as string;

  const currentDate = new Date();
  const oneWeekAgo = new Date(currentDate.setDate(currentDate.getDate() - 7));

  const [startDate, setStartDate] = useState<Date | null>(oneWeekAgo);
  const [endDate, setEndDate] = useState<Date | null>(new Date());

  const handleGetMerchants = async (merchantIds: number[]) => {
    if (deviceToken) {
      try {
        const { data, error } = await getMerchants({ appId: applicationId, deviceToken, merchantIds });

        if (error) {
          console.error(findErrorMessage(error));
          toast.error('Failed to get merchants.');
          return;
        }

        const newMerchantsHashMap =
          data?.merchants.reduce((acc, merchant) => {
            if (!acc[merchant.ID]) acc[merchant.ID] = merchant;
            return acc;
          }, merchants) || merchants;

        setMerchants(newMerchantsHashMap);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const generateCommissionsWithMerchantNames = (
    displayCommissions: DisplayCommission[],
  ): CommissionWithMerchantName[] => {
    const commissionsWithMerchantNames = displayCommissions.map((displayCommission: DisplayCommission) => {
      const MerchantName = merchants[displayCommission.MerchantID]?.Name || 'N/A';
      return {
        ...displayCommission,
        MerchantName,
      };
    });

    return commissionsWithMerchantNames;
  };

  const handleGetCommissions = async (pageNum?: number, pageSize?: number): Promise<CommissionWithMerchantName[]> => {
    const appId = applicationId;
    let commissions;
    if (!startDate || !endDate) {
      toast.error('Please select start/end date.');
      return [];
    }

    try {
      const { displayCommissions } = await getCommissions({
        appId,
        filters: {
          startDate: format(new Date(startDate), DATE_FORMAT),
          endDate: format(addDays(new Date(endDate), 1), DATE_FORMAT),
          searchQueries: searchTerm,
          pageNumber: pageNum || null,
          sortBy: null,
          sortOrder: 'asc',
          pageSize: pageSize || 100,
          // advancedSearch is being set at component rendering, it is only available for /commission-search-advanced-search
          advancedSearch,
        },
      }).unwrap();
      commissions = displayCommissions;
    } catch (error) {
      console.error('Failed to retrieve commission data', error);
      toast.error('Failed to retrieve commission data.');
    }

    if (commissions && Array.isArray(commissions)) {
      const uniqueMerchantIDs = commissions
        .map((commission: DisplayCommission) => commission.MerchantID)
        .filter((merchantId: number) => !merchants[merchantId]);

      if (uniqueMerchantIDs.length !== 0) {
        await handleGetMerchants(uniqueMerchantIDs);
      }
      const commissionsWithMerchantNames = generateCommissionsWithMerchantNames(commissions);
      setPageNumber(pageNumber + 1);
      return commissionsWithMerchantNames;
    } else {
      return [];
    }
  };

  const generateCommissions = async () => {
    setIsLoading(true);
    setHasNextPage(true);
    setLoadedCommissions([]);
    const commissionsWithMerchantNames = await handleGetCommissions(1);
    if (commissionsWithMerchantNames) {
      setLoadedCommissions(commissionsWithMerchantNames);
      setPageNumber(2);
      setIsLoading(false);
    }
  };

  const handleLoadMoreCommissions = async (): Promise<void> => {
    let newCommissions: CommissionWithMerchantName[];
    try {
      newCommissions = await handleGetCommissions(pageNumber);
      if (newCommissions.length !== 0) {
        setLoadedCommissions(loadedCommissions.concat(newCommissions));
      } else {
        setHasNextPage(false);
      }
    } catch (error) {
      console.error('Failed to load more commissions.', error);
    }
  };

  const handleDateChange = (dateRange: DateRange | null) => {
    if (!dateRange) return;
    const [start, end] = dateRange;

    setStartDate(start);
    setEndDate(end);

    if (!hasWfPermissions && user) {
      ReactGA.event('click_date', {
        appId: applicationId,
        userId: user!.sub,
        startDate,
        endDate,
        pageName: 'Commission History',
      });
    }
  };

  const onDatePickerClear = () => {
    setStartDate(null);
    setEndDate(null);
  };

  useEffect(() => {
    if (searchTerm) {
      generateCommissions();
      !hasWfPermissions &&
        ReactGA.event('search', {
          appId: applicationId,
          userId: user!.sub,
          pageName: 'Commission History',
          searchTerm: searchTerm,
        });
      return;
    }
    if (!startDate || !endDate || !deviceToken) return;
    generateCommissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, searchTerm, applicationId, deviceToken]);

  if (!isDeviceTokenFetching && deviceTokenError) {
    console.error(deviceTokenError);
  }

  const { data: appPolicy, error: appPolicyError } = useGetApplicationDetailsQuery(
    {
      appId: applicationId,
      deviceToken,
    },
    {
      skip: !deviceToken || typeof deviceToken !== 'string',
    },
  );

  if (appPolicyError) {
    toast.error('Failed to retrieve application details.');
    console.error(appPolicyError);
  }

  const prevAppPolicyRef = useRef(appPolicy);

  useEffect(() => {
    if (!appPolicy) return;
    const prevAppPolicy = prevAppPolicyRef.current;
    const isAppPolicyChanged = !isEqual(appPolicy, prevAppPolicy);

    if (appPolicy?.Splits && isAppPolicyChanged) {
      const has3WaySplit = appPolicy.Splits.length === 3;
      const hasDeviceSplit = appPolicy.Splits.find(s => s.Part === 'DEVICE');
      const hasUserCommissionColumn = columns.find(
        (c: CombinedColumn) => isCommissionColumn(c) && c.accessor === userCommissionColumn.accessor,
      );

      if (has3WaySplit && hasDeviceSplit) {
        if (!hasUserCommissionColumn) {
          dispatch(
            setCommissionColumns({
              columns: [...columns, userCommissionColumn],
            }),
          );
        }
      } else {
        dispatch(
          setCommissionColumns({
            columns: columns.filter(
              (c: CombinedColumn) => isCommissionColumn(c) && c.accessor !== userCommissionColumn.accessor,
            ),
          }),
        );
      }
    }

    prevAppPolicyRef.current = appPolicy;
  }, [dispatch, columns, appPolicy]);

  const exportToCsv = async (): Promise<void> => {
    if (!startDate || !endDate || !deviceToken) return;
    setIsDownloading(true);
    const csvCommissions = await handleGetCommissions(1, 2000);
    if (csvCommissions.length >= 2000) {
      try {
        const queryString =
          `EventDate:>"${format(startDate, DATE_FORMAT)}" ` +
          `EventDate:<"${format(endDate, DATE_FORMAT)}" ` +
          encodeURIComponent(searchTerm);
        await createCommissionDownloadJob({
          applicationId,
          deviceToken,
          userToken: user!.sub!,
          queryString,
        }).unwrap();
        setModalOpen(true);
      } catch (error) {
        setModalOpen(true);
      }

      return setIsDownloading(false);
    }

    /**
     *  Commissions formatted with the data for Sale Amount, Partner Commission, and User Commission.
     *  Each of these fields initially contains an object with a property for both amount and currency data.
     *  To make these values compatible with CSV format, this restructuring splits each field
     *  into two separate columns: one for the amount and another for the currency.
     * */
    const reformattedCommissions: CsvCommissionWithMerchantName[] = csvCommissions.map(column => ({
      ...column,
      SaleAmount: column.SaleAmount?.amount,
      SaleAmountCurrency: column.SaleAmount?.currency,
      PartnerCommission: column.PartnerCommission?.amount,
      PartnerCommissionCurrency: column.PartnerCommission?.currency,
      BaseCommission: column.BaseCommission?.amount,
      BaseCommissionCurrency: column.BaseCommission?.currency,
      BoostedCommission: column.BoostedCommission?.amount,
      BoostedCommissionCurrency: column.BoostedCommission?.currency,
      UserCommission: column.UserCommission?.amount,
      UserCommissionCurrency: column.UserCommission?.currency,
    }));

    /**
     * Columns formatted for new Currency column corresponding to Sale Amount, Partner Commission, Boosted Commission, and User Commission
     * */
    const reformattedColumns: CsvCommissionColumn[] = [];
    visibleColumns.forEach(column => {
      reformattedColumns.push(column);
      if (
        column.accessor === 'SaleAmount' ||
        column.accessor === 'PartnerCommission' ||
        column.accessor === 'BaseCommission' ||
        column.accessor === 'BoostedCommission' ||
        column.accessor === 'UserCommission'
      ) {
        reformattedColumns.push({
          name: `${column.name} Currency`,
          accessor: `${column.accessor}Currency`,
        });
      }
    });
    const csv = exportJSONToCSV(reformattedCommissions, reformattedColumns);

    if (csv) {
      const blob = new Blob([csv], { type: 'text/csv' });

      const a = document.createElement('a');
      a.style.display = 'none';
      document.body.appendChild(a);
      a.href = URL.createObjectURL(blob);

      a.setAttribute(
        'download',
        `${appName.split(' ').join('_')}_Commission_History_${format(new Date(startDate), DATE_FORMAT)}_to_${format(
          new Date(endDate),
          DATE_FORMAT,
        )}.csv`,
      );

      // trigger download by simulating click
      a.click();

      window.URL.revokeObjectURL(a.href);
      document.body.removeChild(a);

      closeEditColumnsMenu();
      !hasWfPermissions &&
        ReactGA.event('click_export', {
          action: 'click',
          appId: applicationId,
          userId: user!.sub,
          pageName: 'Commission History',
          totalRecords: loadedCommissions.length,
        });
    } else {
      setDownloadError(true);
      setTimeout((): void => setDownloadError(false), 3000);
    }
    setIsDownloading(false);
  };

  const openEditColumnsMenu = (event: MouseEvent<HTMLButtonElement>): void => {
    setEditColumnsAnchorEl(event.currentTarget);
    !hasWfPermissions &&
      ReactGA.event('click_edit', {
        appId: applicationId,
        userId: user!.sub,
        pageName: 'Commission History',
      });
  };

  const closeEditColumnsMenu = (): void => {
    setEditColumnsAnchorEl(null);
  };

  const updateColumns = (nextColumns?: CombinedColumn[]): void => {
    if (!nextColumns) return;

    dispatch(
      setCommissionColumns({
        columns: nextColumns as CommissionColumn[],
      }),
    );
  };

  const closeFilterStatusMenu = (): void => {
    setFilterStatusAnchorEl(null);
    if (hasDatesSelected) {
      generateCommissions();
    }
  };

  const updateStatusFilters = (status: keyof FilterStatus): void => {
    setStatusFilters(prev => ({
      ...prev,
      [status]: !prev[status],
    }));
  };

  const debouncedSearch = useMemo(
    () =>
      debounce((value: string) => {
        setSearchTerm(value);
      }, 500),
    [setSearchTerm],
  );

  const onSearchCommissionHistoryHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
      debouncedSearch(event.target.value);
    },
    [debouncedSearch],
  );

  const onClickClear = (): void => {
    setInputValue('');
    setSearchTerm('');
  };

  const toggleCallback = (): void => {
    setCallbackVisible(x => !x);
  };

  const visibleColumns = useMemo((): CommissionColumnVisible[] => {
    const hasShoppingTripCodes = loadedCommissions.some(commission => !!commission.ShoppingTripCode);
    const hasDeviceXIDs = loadedCommissions.some(commission => !!commission.DeviceXID);

    const newColumns = columns.map(column => {
      if (
        (column.name === 'Shopping Trip Code' && !hasShoppingTripCodes) ||
        (column.name === 'Device XID' && !hasDeviceXIDs)
      ) {
        return { ...column, visible: false };
      }
      return column;
    });
    return newColumns.filter(
      (column: CombinedColumn): column is CommissionColumnVisible => 'visible' in column && column.visible,
    );
  }, [columns, loadedCommissions]);

  const hasDatesSelected = useMemo((): boolean => {
    return !!startDate && !!endDate;
  }, [startDate, endDate]);

  const addHeaderMargin = useMemo((): boolean => {
    return loadedCommissions.length > 20;
  }, [loadedCommissions]);

  function isCommissionColumn(column: CombinedColumn): column is CommissionColumn {
    return (column as CommissionColumn).accessor !== undefined;
  }

  return (
    <div className="commission-history-v2">
      <div className="commission-history-v2-title--block">
        <h1 className="text-muted-dark-purple">Commission History</h1>
      </div>
      <div className="commission-history-v2-header">
        <DateRangePicker
          label="Event Date"
          startDate={startDate ? new Date(startDate) : null}
          endDate={endDate ? new Date(endDate) : null}
          onDateChange={handleDateChange}
          onDateClear={onDatePickerClear}
        />
        <div className="actions">
          <TextField
            className="table-filter-field commission-history-v2-text-field"
            variant="outlined"
            placeholder="Search"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <div className="search-icon">
                    {inputValue ? (
                      <HighlightOff onClick={onClickClear} className="search-bar-icon" />
                    ) : (
                      <Search className="search-bar-icon" />
                    )}
                  </div>
                </InputAdornment>
              ),
            }}
            onChange={(event): void => {
              event.persist();
              setInputValue(event.target.value);
              onSearchCommissionHistoryHandler(event);
            }}
            value={inputValue}
          />

          <div className="btn-dropdown">
            <Button
              className="button-table-action"
              disabled={false}
              variant="outlined"
              aria-controls={EDIT_COLUMNS_MENU_ID}
              aria-haspopup="true"
              endIcon={
                <Icon>
                  <img src={menuBookIcon} height={18} width={18} alt="Columns icon" />
                </Icon>
              }
              onClick={openEditColumnsMenu}
              fullWidth={true}
            >
              Edit Columns
            </Button>

            <Menu
              id={EDIT_COLUMNS_MENU_ID}
              anchorEl={editColumnsAnchorEl}
              keepMounted
              open={!!editColumnsAnchorEl}
              onClose={closeEditColumnsMenu}
            >
              <ChooseColumnsMenu
                columns={columns}
                updateColumns={updateColumns}
                callbackVisible={callbackVisible}
                toggleCallback={toggleCallback}
                isInAppGroupOverview={false}
              />
            </Menu>
            <Menu
              id={FILTER_STATUS_MENU_ID}
              anchorEl={filterStatusAnchorEl}
              keepMounted
              open={!!filterStatusAnchorEl}
              onClose={closeFilterStatusMenu}
            >
              <FilterStatusMenu statusFilters={statusFilters} updateStatusFilters={updateStatusFilters} />
            </Menu>
            <Button
              className="progress-wrapper button-table-action"
              disabled={loadedCommissions.length === 0 || downloadError || isDownloading || !hasDatesSelected}
              onClick={exportToCsv}
              endIcon={
                downloadError ? (
                  <ErrorIcon />
                ) : (
                  <Icon>
                    <img src={exportIcon} height={18} width={18} alt="Export icon" />
                  </Icon>
                )
              }
            >
              {isDownloading && <CircularProgress className="progress" size={18} />}
              Export
            </Button>
            <Link href="https://kb.wildfire-corp.com/article/ygwr-commission-history" target="_blank">
              <Icon>
                <img className="help-icon" src={infoIcon} height={24} width={24} alt="More information icon" />
              </Icon>
            </Link>
          </div>
        </div>
      </div>
      <CommissionHistoryTableV2
        columns={visibleColumns}
        commissions={loadedCommissions}
        applicationId={applicationId}
        isLoading={isLoading}
        hasDatesSelected={hasDatesSelected}
        hasNextPage={hasNextPage}
        loadMoreItems={handleLoadMoreCommissions}
        hasCommissionError={Boolean(commissionError)}
        addHeaderMargin={addHeaderMargin}
        callbackVisible={callbackVisible}
        updateColumns={updateColumns}
        isInAppGroupOverview={false}
      />
      {modalOpen && (
        <Dialog
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          classes={{
            paper: 'modal-padding',
          }}
        >
          <div>
            <div className="close-icon" onClick={() => setModalOpen(false)}>
              <Clear />
            </div>
            <p>The data you requested is too large to download directly.</p>
            {commissionDownloadError ? (
              <p>
                There was an issue starting a download process for your data, please try to download your data again to
                create a new download process.
              </p>
            ) : (
              <p>
                Please visit the{' '}
                <RouterLink
                  className="text-href-blue underline font-bold"
                  to={`/${adminAppId}/app/${applicationId}/commission-download`}
                >
                  Commission History Download
                </RouterLink>{' '}
                page, where it will be available to download soon.
              </p>
            )}
          </div>
        </Dialog>
      )}
    </div>
  );
};

export default CommissionHistory;
