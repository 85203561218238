import React, { useEffect, useRef, useState } from 'react';
import { Button, CircularProgress, Paper } from '@mui/material';
import { capitalize } from 'lodash';
import { toast } from 'react-toastify';
import Error from 'components/Error/Error';
import { findErrorMessage } from 'helpers';
import {
  useAddConceptsMutation,
  useGetConceptsQuery,
  useGetIsAllowListQuery,
  useRemoveConceptsMutation,
  useSyncConceptsMutation,
} from 'reduxState/store/concept/api';
import { useGetDeviceQuery } from 'reduxState/store/user/api';
import { selectUserEmail } from 'reduxState/store/user/selectors';
import ConceptList from './ConceptList';
import { useAppSelector } from '../../reduxState/hooks';
import { Concept } from '../../reduxState/store/concept/types';

export type selectedConceptsType = Record<Concept['ID'], Concept>;

// Used to track expected changes in concepts after saving.
// When these conditions are met, it is safe to sync across all applications.
interface ConceptUpdateReport {
  current: {
    newConcepts: string[];
  };
  addable: {
    newConcepts: string[];
  };
}

interface ConceptManagerProps {
  applicationId: string;
}

const ConceptManager = ({ applicationId }: ConceptManagerProps): JSX.Element => {
  const userEmail = useAppSelector(selectUserEmail);
  const [error, setError] = useState('');
  const [conceptUpdateReport, setConceptUpdateReport] = useState<ConceptUpdateReport | null>(null);
  const selectedAddableRef = useRef<selectedConceptsType>({});
  const selectedCurrentRef = useRef<selectedConceptsType>({});
  const conceptEditSavedCallback = useRef<() => void>(() => null);
  const { data: isAllowList, error: isAllowListError, isError: hasIsAllowListError } = useGetIsAllowListQuery(
    applicationId,
  );

  const [addConcepts, { isLoading: isAddingConceptsLoading }] = useAddConceptsMutation();
  const [removeConcepts, { isLoading: isRemovingConceptsLoading }] = useRemoveConceptsMutation();
  const [syncConcepts] = useSyncConceptsMutation();

  const { data: deviceData, error: deviceTokenError } = useGetDeviceQuery(applicationId);
  const deviceToken = deviceData?.DeviceToken;
  if (hasIsAllowListError) {
    toast.error(`Failed to get application's IsAllowList.`);
    console.error(isAllowListError);
  }

  const getConceptsBaseArgs = {
    appId: applicationId,
    deviceToken: deviceToken!,
  };

  const queryConfig = {
    skip: !deviceToken,
    pollingInterval: Boolean(conceptUpdateReport) ? 5000 : 0, // polling interval of 0 stops polling. we should only be polling while an update is in progress.
  };

  const {
    data: currentDomainConcepts,
    isFetching: isCurrentDomainConceptsFetching,
    refetch: refetchCurrentDomainConcepts,
  } = useGetConceptsQuery({ ...getConceptsBaseArgs, kind: 'domain' }, queryConfig);

  const {
    data: currentMerchantConcepts,
    isFetching: isCurrentMerchantConceptsFetching,
    refetch: refetchCurrentMerchantConcepts,
  } = useGetConceptsQuery({ ...getConceptsBaseArgs, kind: 'merchant' }, queryConfig);

  const {
    data: addableDomainConcepts,
    isFetching: isAddableDomainConceptsFetching,
    refetch: refetchAddableDomainConcepts,
  } = useGetConceptsQuery({ ...getConceptsBaseArgs, kind: 'domain', addable: true }, queryConfig);

  const {
    data: addableMerchantConcepts,
    isFetching: isAddableMerchantConceptsFetching,
    refetch: refetchAddableMerchantConcepts,
  } = useGetConceptsQuery({ ...getConceptsBaseArgs, kind: 'merchant', addable: true }, queryConfig);

  const isConceptsSaving = isAddingConceptsLoading || isRemovingConceptsLoading;
  const isConceptsFetching =
    isCurrentDomainConceptsFetching ||
    isCurrentMerchantConceptsFetching ||
    isAddableDomainConceptsFetching ||
    isAddableMerchantConceptsFetching;

  const editConcepts = async (shouldSync = false) => {
    // users should not be able to save concepts without isAllowList or deviceToken
    if (deviceTokenError || !deviceToken || hasIsAllowListError) {
      toast.error('Unable to save concepts.');
      if (deviceTokenError) {
        console.error(deviceTokenError || 'Failed to retrieve device token.');
      }
      return;
    }

    if (!addableDomainConcepts || !addableMerchantConcepts || !currentDomainConcepts || !currentMerchantConcepts)
      return;
    const newAddedConcepts = Object.keys(selectedAddableRef.current);
    const newRemoveConcepts = Object.keys(selectedCurrentRef.current);

    let conceptsToAdd: string[] = [];
    let conceptsToRemove: string[] = [];

    // if isAllowList is true, business as usual
    // otherwise, do the opposite
    if (isAllowList) {
      conceptsToAdd = newAddedConcepts;
      conceptsToRemove = newRemoveConcepts;
    } else {
      conceptsToAdd = newRemoveConcepts;
      conceptsToRemove = newAddedConcepts;
    }

    if (!conceptsToAdd.length && !conceptsToRemove.length) return;

    if (shouldSync) {
      setConceptUpdateReport({
        current: {
          newConcepts: newAddedConcepts,
        },
        addable: {
          newConcepts: newRemoveConcepts,
        },
      });
    }

    try {
      const addConceptsPromise = addConcepts({
        appId: applicationId,
        deviceToken,
        body: { shortCodes: conceptsToAdd, author: userEmail },
      }).unwrap();
      const removeConceptsPromise = removeConcepts({
        appId: applicationId,
        deviceToken,
        body: { shortCodes: conceptsToRemove, author: userEmail },
      }).unwrap();
      await Promise.all([addConceptsPromise, removeConceptsPromise]);
      !shouldSync && toast.success('Concepts saved successfully!');
      conceptEditSavedCallback.current();
    } catch (errorResponse) {
      let errorMessage = 'Failed to add/remove concepts.';
      const error = errorResponse.data;
      if (error?.ErrorMessage) {
        errorMessage = capitalize(error.errorMessage);
      }
      setError(errorMessage);
      toast.error(errorMessage);
    }
  };

  const refetchConcepts = () => {
    refetchCurrentDomainConcepts();
    refetchCurrentMerchantConcepts();
    refetchAddableDomainConcepts();
    refetchAddableMerchantConcepts();
    setError('');
  };

  useEffect(() => {
    if (
      !currentDomainConcepts ||
      !currentMerchantConcepts ||
      !addableDomainConcepts ||
      !addableMerchantConcepts ||
      !deviceToken ||
      !conceptUpdateReport
    )
      return;

    const { current, addable } = conceptUpdateReport;
    const newCurrentConceptsAdded = new Set(current.newConcepts);
    const newAddableConceptsAdded = new Set(addable.newConcepts);

    const foundNewCurrentConceptsCount = [
      ...currentDomainConcepts.concepts,
      ...currentMerchantConcepts.concepts,
    ].reduce(
      // if the current concept for this iteration is one of the concepts that are being added, increase count by one.
      (foundCount, concept) => foundCount + Number(newCurrentConceptsAdded.has(concept.ID)),
      0,
    );
    const foundNewAddableConceptsCount = [
      ...addableDomainConcepts.concepts,
      ...addableMerchantConcepts.concepts,
    ].reduce(
      // if the addable concept for this iteration is one of the concepts that are being made addable, increase count by one.
      (foundCount, concept) => foundCount + Number(newAddableConceptsAdded.has(concept.ID)),
      0,
    );

    if (
      foundNewAddableConceptsCount === newAddableConceptsAdded.size &&
      foundNewCurrentConceptsCount === newCurrentConceptsAdded.size
    ) {
      syncConcepts({
        appId: applicationId,
        deviceToken,
        body: { applicationId: Number(applicationId), author: userEmail },
      })
        .unwrap()
        .then(() => {
          toast.success(
            'Concepts have been updated successfully! Concepts are now being synced across all applications.',
          );
        })
        .catch(error => {
          toast.error(`Unable to sync concepts across all applications: ${findErrorMessage(error)}`);
          console.error(error);
        })
        .finally(() => setConceptUpdateReport(null));
    }
  }, [currentDomainConcepts, currentMerchantConcepts, addableDomainConcepts, addableMerchantConcepts]);

  // Helper function to sort concepts alphabetically by Value
  const sortConcepts = (concepts: Concept[]): Concept[] => {
    return concepts.sort((a, b) => a.Value.localeCompare(b.Value));
  };

  // Combine and sort the concepts
  const currentConcepts = {
    concepts: sortConcepts([...(currentDomainConcepts?.concepts || []), ...(currentMerchantConcepts?.concepts || [])]),
  };

  const addableConcepts = {
    concepts: sortConcepts([...(addableDomainConcepts?.concepts || []), ...(addableMerchantConcepts?.concepts || [])]),
  };

  return (
    <div className="flex flex-1 flex-col">
      <div className="flex flex-row flex-wrap items-center justify-between">
        <h1 className="text-muted-dark-purple">Concepts</h1>
        {!error && (
          <div className="flex gap-3">
            {Boolean(conceptUpdateReport) ? (
              <Paper elevation={3} className="flex justify-between items-center p-2 mb-3">
                <CircularProgress color="info" size="2rem" />
                <p className="pl-2.5 w-[425px]">
                  Please remain on this page until all updates are saved. Once concepts are updated successfully, you
                  may navigate away.
                </p>
              </Paper>
            ) : (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={isConceptsSaving || isConceptsFetching || hasIsAllowListError}
                  onClick={() => editConcepts(false)}
                >
                  {isConceptsSaving ? (
                    <>
                      <CircularProgress color="info" size="0.875rem" />
                      <span className="pl-2.5">Saving...</span>
                    </>
                  ) : (
                    <span>Save</span>
                  )}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={isConceptsSaving || isConceptsFetching || hasIsAllowListError}
                  onClick={() => editConcepts(true)}
                >
                  Save and Sync
                </Button>
              </>
            )}
          </div>
        )}
      </div>
      {error ? (
        <Error retry={() => refetchConcepts()} />
      ) : (
        <div className="flex flex-1 flex-col">
          <div className="flex flex-1 gap-5">
            <ConceptList
              title="Current"
              isLoading={Boolean(
                isCurrentDomainConceptsFetching || isCurrentMerchantConceptsFetching || conceptUpdateReport,
              )}
              concepts={currentConcepts.concepts}
              selectedRef={selectedCurrentRef}
              conceptEditSavedCallback={conceptEditSavedCallback}
            />
            <ConceptList
              title="Addable"
              isLoading={Boolean(
                isAddableDomainConceptsFetching || isAddableMerchantConceptsFetching || conceptUpdateReport,
              )}
              concepts={addableConcepts.concepts}
              selectedRef={selectedAddableRef}
              conceptEditSavedCallback={conceptEditSavedCallback}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ConceptManager;
